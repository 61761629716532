
import { defineComponent, ref, computed, watch, reactive, onMounted } from "vue";
import { setNewPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useRouter } from "vue-router";
import Pagination from "@/components/Pagination.vue";
import { useI18n } from "vue-i18n";
import TableSkeleton from "@/components/skeleton/Table.vue";
import { MenuComponent } from "@/assets/ts/components";
import {
  useQuery,
  useMutateQuery,
} from "@/core/helpers/common-helper";
import useChannelList from "@/core/services/compositions/reconcile/useChannelList";
import { ElNotification } from 'element-plus'
import useDownload from "@/core/helpers/download";
import FilterReconcile from "./filter-reconcile.vue"

export default defineComponent({
  components: {
    Pagination,
    TableSkeleton,
    FilterReconcile
  },
  setup() {
    // init
    const { t } = useI18n();
    const submitButton = ref<null | HTMLButtonElement>(null);
    const paginationRef = ref(null);
    const { query } = useQuery();
    const router = useRouter();

    // breadcrumb
    setNewPageBreadcrumbs(t("reconcile.channel.breadcrumb"), [{ title: t("reconcile.channel.title") }]);

    const form = reactive({
      date: '',
    })

    const fetchParams = computed(() => ({
      page: query.value.page || 1,
      limit: query.value.limit || 20,
      date: query.value.date || form.date,
    }));

    const { data, isValidating, mutate: refreshList, error } = useChannelList(() => fetchParams.value);

    watch(error, (error) => {
      if (error.response.status == 403) {
        router.push({ name: "403" });

        return;
      }
      ElNotification({
        title: error.response.data.rc,
        message: error.response.data.message,
        type: 'error',
      })
    });

    const { mutateQuery } = useMutateQuery();
    const handleChangePage = (nextPage: number) => {
      mutateQuery({
        page: nextPage,
      });
    };

    const filtered = (filters) => {
      MenuComponent.hideDropdowns(undefined);
      Object.assign(form, filters);

      mutateQuery({
        page: 1,
        date: form.date,
      });
    };

    const { handleDownloadToFile, isDownloading } = useDownload();

    const download = (file) => {
      handleDownloadToFile(`/app/reconcile/channel-file/bmi/${file}`, file);
    }

    onMounted(() => {
      MenuComponent.reinitialization();
    });

    return {
      isValidating,
      data,
      paginationRef,
      handleChangePage,
      download,
      isDownloading,
      submitButton,
      filtered
    };
  },
});
