
import { defineComponent, reactive } from "vue";
import moment from 'moment';

interface Filter {
  date: string;
}

export default defineComponent({
  name: "filter-reconcile",
  components: {},
  setup(props, context) {

    const data = reactive<Filter>({
      date: '',
    });

    const filter = () => {
      context.emit('filtered', data);
    };

    const reset = () => {
      data.date = '';
    };

    return {
      data,
      filter,
      reset,
    };
  }
});
